import React, { useContext, useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { SEO } from "components"
import SubpageLayout from "layouts/SubpageLayout"
import { Headline, HeadlineSizes, Paragraph, ParagraphSizes } from "ui"
import Wrapper, { WrapperSizes } from "components/Wrapper"
import { State } from "layouts"

const query = graphql`
  {
    allWpPage(filter: { notfound: { notfoundLang: { regex: "/ES|EN/" } } }) {
      edges {
        node {
          notfound {
            notfoundHeadline
            notfoundParagraph
            notfoundLang
          }
        }
      }
    }
  }
`

const NotFoundPage = ({ location }) => {
  const {
    state: { selectedLang },
  } = useContext(State)
  const { allWpPage } = useStaticQuery(query)
  const [currentData, setCurrentData] = useState(null)

  useEffect(() => {
    if (selectedLang) {
      const {
        node: { notfound },
      } = allWpPage?.edges?.find(
        lang => lang?.node?.notfound?.notfoundLang === selectedLang
      )

      setCurrentData(notfound)
      return
    }

    const {
      node: { notfound },
    } = allWpPage?.edges?.find(
      lang => lang?.node?.notfound?.notfoundLang === "ES"
    )

    setCurrentData(notfound)
  }, [selectedLang])

  return (
    <SubpageLayout path={location.pathname}>
      <SEO title="404" />
      <Wrapper size={WrapperSizes.SMALL}>
        <Headline tag="h1" sizeType={HeadlineSizes.XL}>
          {currentData?.notfoundHeadline}
        </Headline>
        <Paragraph sizeType={ParagraphSizes.M}>
          {currentData?.notfoundParagraph}
        </Paragraph>
      </Wrapper>
    </SubpageLayout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default NotFoundPage
